import classnames from 'classnames/bind'
import { format } from 'date-fns'
import { orderBy, compact } from 'lodash'
import { AbsenceCode, WorkDay } from '../lib/domain'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../css/MonthSummary.module.css'
import dayListStyles from '../css/DayList.module.css'
import { Locale } from '../lib/reducers/settings'
import RoundedButton from '../RoundedButton'
import { updateWorkMonth } from '../lib/hoursApi'
import { formatISOMonth, monthRange } from '../lib/date'

const css = classnames.bind(styles)

type MonthHourSummary = {
  totalHours: number
  codeTotals: Record<string, number>
}

type MonthSummaryProps = {
  dateISO: string
  serverDays: Record<string, WorkDay>
  flexitimeDelta: number
  displayFlexitime: boolean
  locale: Locale
  isLocked: boolean
  monthHasErrors: boolean
  isPastDue?: boolean
  hasUpcomingAbsence?: boolean
  dataOwnerUserName: string
  absenceCodes: AbsenceCode[]
}

export const MonthSummary: React.FC<MonthSummaryProps> = ({
  dateISO,
  serverDays,
  flexitimeDelta,
  displayFlexitime,
  locale,
  isLocked,
  monthHasErrors,
  isPastDue,
  hasUpcomingAbsence,
  dataOwnerUserName,
  absenceCodes,
}) => {
  const dispatch = useDispatch()
  const month = formatISOMonth(dateISO)
  const monthName = format(dateISO, 'MMMM')
  const [isSaving, setIsSaving] = useState(false)

  const summary = getMonthHoursSummary(month, serverDays)
  const translations = locale.texts.hoursSummary

  const isAbsenceCode = (hourCode: string) => absenceCodes.find(({ code }) => code === hourCode.toLowerCase())

  const codesInOrder = orderBy(
    Object.keys(summary.codeTotals),
    (k) => (isAbsenceCode(k) ? Number(summary.codeTotals[k]) - Number.MAX_SAFE_INTEGER : summary.codeTotals[k]),
    ['desc']
  )
    .map((k) => ({
      code: k,
      hours: summary.codeTotals[k],
    }))
    .filter(({ hours }) => hours && !isNaN(hours))

  const lockButtonText = isLocked ? translations.backToEditing : translations.markComplete

  const toggleIsLocked = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (confirm(translations.confirmEdit(monthName, isLocked)) === true) {
      setIsSaving(true)
      const workMonth = { month, locked: !isLocked }
      try {
        await updateWorkMonth(dataOwnerUserName, workMonth)
        dispatch({ type: 'UPDATE_SERVER_MONTH', workMonth })
      } finally {
        setIsSaving(false)
      }
    }
  }

  const formatFlex = (flex: number) => {
    if (flex === 0) {
      return '± 0 h'
    }
    return flex > 0 ? `+${flex} h` : `${flex} h`
  }

  const readyToMarkComplete = !monthHasErrors && !isLocked

  let message = ''
  if (isLocked) {
    message = translations.monthMarkedAsComplete(format(dateISO, 'MMMM'))
  } else if (hasUpcomingAbsence) {
    message = translations.markCompleteBeforeAbsence
  } else if (monthHasErrors) {
    message = translations.fillAllHoursCorrectly
  } else if (readyToMarkComplete) {
    message = translations.rememberToMarkComplete
  }

  const stickTop = isPastDue
  const stickBottom = hasUpcomingAbsence || readyToMarkComplete

  const currentAndPreviousMonth = monthRange(1, 0).map(formatISOMonth)
  const allowedToEditMonth = currentAndPreviousMonth.includes(month)

  const disableMonthLockButton = (!isLocked && monthHasErrors) || !allowedToEditMonth || isSaving

  return (
    <div
      className={css('monthSummary', dayListStyles.item, {
        isPastDue,
        hasUpcomingAbsence,
        stickTop,
        stickBottom,
      })}
      id={`${month}-summary`}
      data-test={`dayList-monthSummary-${month}`}
      data-test-is-sticky={stickTop || stickBottom}
    >
      <div className={css('monthSummaryList')}>
        <span className={styles.title}>
          {translations.monthSummary(monthName)} {translations.summary}
        </span>
        <table className={styles.hoursSummary}>
          <tbody data-test={`dayList-monthSummary-${month}-table`}>
            {codesInOrder.map(({ code, hours }) => (
              <tr
                key={`code-${month}-${code}`}
                data-test={`code-${month}-${code}`}
                className={css({
                  absenceCode: isAbsenceCode(code),
                })}
              >
                <td>{code}</td>
                <td>{hours} h</td>
              </tr>
            ))}
            {displayFlexitime && (
              <tr data-test={`month-total-flex-${month}`} className={styles.topPadding}>
                <td>{translations.deltaFlex}</td>
                <td>{formatFlex(flexitimeDelta)}</td>
              </tr>
            )}
            <tr data-test={`month-total-${month}`} className={css('topPadding', 'boldText')}>
              <td>{translations.total}</td>
              <td>{summary.totalHours} h</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={css('monthSummaryMessage')}>
        <span
          className={css('text', { textProminent: hasUpcomingAbsence || readyToMarkComplete })}
          data-test={`summary-message`}
        >
          {message}
        </span>
      </div>
      <div className={css('monthSummaryLockButtonContainer')}>
        {
          <RoundedButton
            className={css('monthSummaryLockButton', {
              monthSummaryUnlockButton: isLocked,
              attentionAnimation: readyToMarkComplete,
            })}
            data-test={`summary-lockButton`}
            onClick={toggleIsLocked}
            disabled={disableMonthLockButton}
          >
            {lockButtonText}
          </RoundedButton>
        }
      </div>
    </div>
  )
}

const getMonthHoursSummary = (month: string, days: Record<string, WorkDay>): MonthHourSummary => {
  const workDays = compact(
    Object.keys(days)
      .filter((d) => d.startsWith(month))
      .map((d) => days[d])
  )

  const parseFloatOrDefault = (s: string) => {
    const parsed = parseFloat(s)
    return isNaN(parsed) ? 0 : parsed
  }

  const totalHours = workDays.reduce(
    (sumTotal, d) => sumTotal + d.entries.reduce((subSum, e) => subSum + parseFloatOrDefault(e.hours), 0),
    0
  )
  const codeTotals: Record<string, number> = {}

  workDays.forEach((d) => {
    d.entries.forEach((e) => {
      if (!codeTotals[e.hourCode]) {
        codeTotals[e.hourCode] = 0
      }
      codeTotals[e.hourCode] += parseFloatOrDefault(e.hours)
    })
  })

  return { totalHours, codeTotals }
}
